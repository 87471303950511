.react-toast-container {
  z-index: 1500;
  position: fixed;
  top: 20px;
  right: 20px;
  /* display: none; */
  transform: translateY(-500px);
  transition: 0.4s;
}
.react-toast-container.show {
  /* display: grid; */
  transform: translateY(0);
}
